$theme_color: #ff447c;

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
}

a {
  text-decoration: none;
}

label {
  width: 100%;
  font-size: 14px;
  color: #426a6d;
  position: relative;
  margin-bottom: 20px;
}

.page-item.active .page-link{
  background-color: darkcyan;
  border-color: #007b7b;
}
.page-link, .dataTable-pagination a{
  color: darkcyan;
}
.page-link:focus{
  box-shadow: none;
  color: darkcyan;
}
.page-link:hover{
  color: darkcyan;
}
.error-message {
  position: absolute;
  color: red;
  right: 0;
}
table{
  font-size: 14px;
  th{
    text-align: center;
    vertical-align: middle;
  }
  td{
    vertical-align: middle;
    text-align: center;
  }
  .table-category-icon{
    width: 50px;
    border: 2px solid rgba(0, 139, 139, 0.35);
    border-radius: 5px;
  }
  a{
    color: darkcyan;
  }
}

.sort-button {
  background: darkcyan;
  border: 1px solid darkcyan;
  padding: 4px 15px;
  height: fit-content;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: 125px;
  transition: all .3s ease-in-out;
  &:hover{
    background: white;
    color: darkcyan;
  }
}
.search-button{
  background: darkcyan;
  border: 1px solid darkcyan;
  padding: 5px 15px;
  border-radius: 5px;
  width: 96px;
  text-align: center;
  height: 33px;
  color: white;
  font-size: 14px;
  transition: all .3s ease-in-out;
  &:hover{
    background: white;
    color: darkcyan;
  }
}
.btn{
  &:focus{
    box-shadow: none;
  }
}
.clear-filters {
  background: tomato;
  color: white;
  border: 1px solid tomato;
  border-radius: 5px;
  width: 180px;
  font-size: 14px;
  padding: 5px 15px;
  transition: all .3s ease-in-out;
  &:hover{
    background: white;
    color: tomato;
  }
}
.my-card{
  min-height: 250px;
}


.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu{
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: tomato;
  }
  &::-webkit-scrollbar-thumb {
    background: #0eadb2;
    &:hover{
      background: #004a4a;
    }
  }
}

.card-loader{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.11);
  z-index: 9;
  img{
    left: 50%;
    top: 50%;
    position: absolute;
    z-index: 10;
    transform: translate(-50%, -50%);
  }
}
.link-button-card{
  background: darkcyan;
  border: 1px solid darkcyan;
  color: white;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  padding: 3px 6px 0 6px;
  &:hover{
    color: darkcyan;
    background: white;
  }
}
.form-control, .form-select {
  color: gray;

  &:focus {
    box-shadow: none;
    color: #426a6d;
  }
}

.theme-text {
  color: darkcyan;
}

.create-button {
  background-image: linear-gradient(90deg, darkcyan, #00ffff, darkcyan);
  border: 2px solid darkcyan;
  border-radius: 5px;
  padding: 3px;
  transition: all .3s ease-in-out;
  margin-top: 15px;

  &:hover {
    background-image: linear-gradient(90deg, darkcyan, #006262, darkcyan);
    color: white;
    border: 2px solid #006262;
  }
}

.category-icon-container {
  width: 110px;
  height: 110px;
  border: 5px solid darkcyan;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  background: black;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    opacity: .4;
  }
  i{
    position: absolute;
    font-size: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 15px #00ffd0;
    cursor: pointer;
    z-index: 9;
    transition: all .3s ease-in-out;
    &:hover{
      font-size: 30px;
    }
  }
}

.logo {
  width: 65%;
  margin-left: 30px;
}

.theme-bg {
  background-image: linear-gradient(90deg, #002727, #005a5a, #002727);
}

.sb-sidenav-dark {
  background-image: linear-gradient(180deg, #002727, #005a5a, #002727) !important;
}

.sb-sidenav-dark .sb-sidenav-footer {
  background-image: linear-gradient(90deg, #002727, #005a5a, #002727) !important;
}

.footer {
  color: gray;

  a {
    color: gray;
    text-decoration: none;
    padding: 0 3px;
  }
}

.login-container {
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(#00565a, #000000);
  position: relative;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: .25;
  }

  .login-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #025b64c4;
    padding: 25px;
    border-radius: 20px;
    width: 350px;
    box-shadow: 0 5px 40px black;

    label {
      font-size: 12px;
      color: black;
      position: relative;

      .login-error-message {
        position: absolute;
      }
    }

    input {
      margin-top: 2px;
      font-size: 12px;
      color: gray;
    }
  }
}

.sb-sidenav-menu-nested{
  .nav-link{
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
  }
}
.user-photo{
  height: 50px;
}
.text-tomato{
  color: tomato;
}
.place-card {
  text-align: center;
  padding: 25px;

  i {
    color: $theme-color;
  }

  h4 {
    margin-top: 10px;
  }
}

.my-card-front {
  box-shadow: 0 0 20px silver;
  transition: .3s all ease-in-out;
  border: none;

  .my-card-header {
    background: rgba(255, 0, 0, 0.1) !important;
    border: none;

    h4 {
      color: #ff447c;
      text-align: center;
    }
  }

  .card-body {
    h1 {
      color: #ff447c;
      text-align: center;
      margin: 15px 0;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        text-align: center;
        padding: 5px 0;
      }
    }

    button {
      background: #ff447c;
      color: white;
      border: 2px solid #ff447c;
      padding: 5px;
      text-align: center;
      transition: .3s all ease-in-out;

      &:hover {
        background: white;
        color: #ff447c;
      }
    }
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}
.link-type h4 {
  color: #363f48;
}
.listing-card-header {
  background: rgba(0, 139, 139, 0.50);
}

.listing-card {
  border: 1px solid rgba(0, 139, 139, .75);
}

.preview-image {
  width: 85px;
}
.day-input-section {
  padding: 5px 10px;
  border: 1px solid silver;
  border-radius: 10px;
}
.table-left-align{
  th, td{
    text-align: left!important;
  }
}
.details-photo-item {
  width: 32% !important;
  margin: 5px;
}
.cursor-pointer{
  cursor: pointer;
}